import React, { useState, useRef } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { format } from "date-fns";
import {
  Form,
  Input,
  Dropdown,
  Button,
  Message,
  TextArea,
} from "semantic-ui-react";
import { targets as learningTargets } from "./learningTargets.json";

const ScheduleRetake = () => {
  const [studentName, setStudentName] = useState("");
  const [standard, setStandard] = useState("");
  const [date, setDate] = useState("");
  const [comments, setComments] = useState("");
  const [period, setPeriod] = useState("");
  const [error, setError] = useState(false);
  const [sending, setSending] = useState(false);
  const dateRef = useRef(null);
  const [scheduled, setScheduled] = useState({
    date: "",
    standard: "",
    period: "",
  });

  const formValid = studentName && standard && date && period;

  const periodOptions = {
    PERIOD_1: "Period 1",
    PERIOD_2: "Period 2",
    PERIOD_3: "Period 3",
    PERIOD_4: "Period 4",
    LUNCH: "Lunch",
    PERIOD_5: "Period 5",
    PERIOD_6: "Period 6",
    PERIOD_7: "Period 7",
    AFTER_SCHOOL: "After school",
  };

  return (
    <Form className="ScheduleRetake">
      <h1>Schedule a Retake</h1>

      {scheduled.date && (
        <Message
          success
          visible
          header="A retake has been scheduled!"
          content={`Mr. Piechocki will see you during ${
            periodOptions[scheduled.period]
          } to retake ${scheduled.standard} on ${format(
            scheduled.date,
            "MMMM do"
          )}.`}
        />
      )}

      {error && (
        <Message
          negative
          visible
          header="Failed to schedule retake."
          content="Your retake could not be scheduled."
        />
      )}

      <div className="formItem">
        <label htmlFor="name">Name</label>
        <Input
          fluid
          id="name"
          value={studentName}
          onChange={(e, { value }) => setStudentName(value)}
        />
      </div>

      <div className="formItem">
        <label htmlFor="standard">Standard</label>
        <Dropdown
          id="standard"
          fluid
          search
          selection
          value={standard}
          options={learningTargets.map((t) => ({ key: t, value: t, text: t }))}
          onChange={(e, { value }) => setStandard(value)}
        />
      </div>

      <div className="formItem">
        <label htmlFor="retakeDate">Retake Date</label>
        <SemanticDatepicker
          id="retakeDate"
          fluid
          ref={dateRef}
          format="MM-DD-YYYY"
          onChange={(e, d) => setDate(d.value)}
        />
      </div>

      <div className="formItem">
        <label htmlFor="period">Period</label>
        <Dropdown
          id="period"
          fluid
          selection
          value={period}
          options={Object.keys(periodOptions).map((period) => ({
            key: period,
            value: period,
            text: periodOptions[period],
          }))}
          onChange={(e, { value }) => setPeriod(value)}
        />
      </div>

      <div className="formItem">
        <label htmlFor="comments">Comments (Optional)</label>
        <TextArea
          id="comments"
          value={comments}
          rows={2}
          onChange={(e, { value }) => setComments(value)}
        />
      </div>

      <Button
        primary
        type="submit"
        disabled={!formValid || sending}
        loading={sending}
        onClick={async () => {
          setSending(true);
          setScheduled({ date: "", period: "", standard: "" });

          setError(false);
          const res = await fetch("/.netlify/functions/schedule-retake", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              studentName,
              standard,
              date,
              comments,
              period,
            }),
          });

          setSending(false);

          if (res.ok) {
            setScheduled({ standard, date, period });
            setStandard("");
            setComments("");
            setPeriod("");
            dateRef.current.resetState();
            setDate("");
          } else {
            setError(true);
          }
        }}
      >
        Submit
      </Button>
    </Form>
  );
};

export default ScheduleRetake;
