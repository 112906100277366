import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ScheduleRetake from "./ScheduleRetake";
import PhysicsCubeActivity from "./PhysicsCubeActivity";
import Home from "./Home";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/cube" component={PhysicsCubeActivity} />
        <Route exact path="/retake" component={ScheduleRetake} />
        <Route exact path="/" component={Home} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
