import React from "react";
import pieSlice from "./pie-slice.svg";

const Home = () => {
  return (
    <div className="Home">
      <img src={pieSlice} height="300px" alt="" />
      <h1>Standard Pie</h1>
    </div>
  );
};

export default Home;
