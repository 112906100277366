import React, { useEffect, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Dropdown } from "semantic-ui-react";

const PhysicsCubeActivity = () => {
  const [currentLevel, setCurrentLevel] = useState(0);
  const levelOptions = [...Array(5).keys()];

  useEffect(() => {
    const cubeContainer = document.querySelector("#cube-scene");
    const cubeCanvas = document.querySelector("#cube-scene canvas");

    if (cubeCanvas) {
      cubeCanvas.remove();
    }

    const camera = new THREE.PerspectiveCamera(
      70,
      cubeContainer.clientWidth / cubeContainer.clientHeight,
      1,
      1000
    );

    camera.position.z = 200;

    const scene = new THREE.Scene();

    const renderer = new THREE.WebGLRenderer({ antialias: true });

    renderer.setPixelRatio(window.devicePixelRatio);

    const controls = new OrbitControls(camera, renderer.domElement);

    renderer.setSize(cubeContainer.clientWidth, cubeContainer.clientHeight);

    const textureLoader = new THREE.TextureLoader();
    textureLoader.setPath(`img/cube-activity/level-${currentLevel}/`);

    const faceArr = [...Array(5).keys()];
    faceArr.splice(3, 0, "blank");

    const textures = faceArr.map((imgName) => {
      const txt = textureLoader.load(
        imgName === "blank"
          ? "./../blank.png"
          : `l${currentLevel}-${imgName}.png`
      );

      txt.anisotropy = renderer.getMaxAnisotropy();
      txt.minFilter = THREE.LinearMipMapNearestFilter;

      return txt;
    });

    const materials = textures.map(
      (texture) => new THREE.MeshBasicMaterial({ map: texture })
    );

    var geometry = new THREE.BoxBufferGeometry(100, 100, 100);

    const mesh = new THREE.Mesh(geometry, materials);
    scene.add(mesh);

    cubeContainer.appendChild(renderer.domElement);

    window.addEventListener("resize", onWindowResize, false);

    function onWindowResize() {
      camera.aspect = cubeContainer.clientWidth / cubeContainer.clientHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(cubeContainer.clientWidth, cubeContainer.clientHeight);
    }

    function animate() {
      requestAnimationFrame(animate);

      controls.update();

      renderer.render(scene, camera);
    }

    animate();
  }, [currentLevel]);

  return (
    <div className="InquiryCube">
      <h1>Inquiry Cube</h1>
      <Dropdown
        id="level"
        selection
        value={currentLevel}
        options={levelOptions.map((option) => ({
          key: option,
          value: option,
          text: `Level ${option}`,
        }))}
        onChange={(e, { value }) => setCurrentLevel(value)}
      />
      <div id="cube-scene"></div>
    </div>
  );
};

export default PhysicsCubeActivity;
